@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

:root{
  /* fonts*/
  --font-ubuntu: 'Ubuntu', sans-serif;

  /* color*/
  --bg: #EFF5FF;
  --white: #FFF;
  --bg-pink: #483EFF;
  --md: 768px;
  --light-blue: #ABBCFF;
  --sky-blue: #BEE2FD;
  --denim: #022959;
  --grey: #9699AA;
  --red-errors: #EE374A;
  --purple: #483EFF;
  --border-color: #D6D9E6;
  --very-light-grey: #F8F9FF;
}


